.popup{
    width: 100%;
}
.mainContainer{
    display: grid;
    grid-template-columns: 20% 80%;
    overflow-x: hidden;
    height: 84vh;
}
.overflow{
    overflow-y: hidden !important;
}
.popupTitle{
    color: var(--text);
}
.popupTitle::first-letter{
    text-transform: capitalize;
}
.popupElipis{
    padding: 4px 0;
    margin: 13px 0;
    text-transform: capitalize;
}
.closeMenu{
    color: black;
    font-size: 30px;
    font-weight: 500;
}
.decription{
    font-size: 16px;
    font-weight: 500;
}
.activeMenu{
    border: 2px solid var(--text);
    margin: 0;
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 5px;
}
.nonActiveMenu{
    margin: 0;
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 5px;
}

.imgHeight{
    width: 600px;
}

.iframe{
    padding: 0 0 30px 0;
}
.iframeTag{
    width:560px;
}
.ytContainer{
    padding-top: 40px;
}
.ytTitle{
    text-decoration: underline;
}
.codeContainer{
    height: 100px;
    width: 100%;
    padding-bottom: 30px;
}
.loop{
    padding-bottom: 50px;
}
.markettingSection{
    padding: 15px 0 0 0;
}
.attrTitle{
    color: var(--text);
    font-size: 25px;
}
.email{
    outline: none;
    border: 2px solid var(--primary-color);
    height: 35px;
    width: 100%;
}
.feedback{
    height: 100px;
    width: 100%;
    margin-top: 5px;
    resize: none;
    border-color:var(--footerbg);
}
.feedBtn{
    height: 30px;
    width: 50%;
    font-weight: 600;
    color: var(--text);
    background: var(--footerbg);
    border: none;
}
.resources{
    color: var(--text);
}
.techList .techCard:first-child{
    height: 100px;
    width: 100%;
    background: var(--footerbg);
    color: white;
    text-shadow: 
                -1px -1px 0 var(--text),  
                 1px -1px 0 var(--text),
                -1px  1px 0 var(--text),
                 1px  1px 0 var(--text);
    border-radius: 10px;
    position: relative;
}
.techCard img{
    position: absolute;
    position: absolute;
    bottom: 10px;
    right: 15px;
}
.techList .techCard:not(:first-child) {
    height: 100px;
    width: 100%;
    background: var(--footerbg);
    color: white;
    text-shadow: 
                -1px -1px 0 var(--text),  
                 1px -1px 0 var(--text),
                -1px  1px 0 var(--text),
                 1px  1px 0 var(--text);
    border-radius: 10px;
    margin-top: 17%;
    position: relative;
}
.techCard h2{
    padding: 4px;
}
.socialRow{
    margin: 20px 0;
    display: flex;
    justify-content: center;
}
.socialLogo{
    height: 40px;
    width: 40px;
    padding-right: 30px;
}
.shaking .socialLogo{
    height: 30px;
    width: 30px;
    padding-right: 10px;
}
.refRow a{
    text-decoration: none;
    color: white;
}
.socialLogoYt{
    height: 40px;
    width: 40px;
}
.strickout{
    text-decoration: line-through;
}
.feedbtn{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.feedRow{
    display: flex;
    padding: 10px 0;
}
.feedTitle{
    font-weight: 900;
}
.refRow{
    display: flex;
}

.shaking {
    animation: wiggle 2s linear infinite;
    transform-origin: 50% 3em;
    background: var(--primary-color);
    height: 40px;
    width: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 5px 7px 10px;
  }
  .shaking a{
    color: white;
    text-decoration: none;
  }
  .menuSpacing{
    padding: 0px 10px;
  }
  .sideTitle{
    text-transform: capitalize;
  }
  .isMoile{
    display: block;
  }
  .firstContainer{
    width: 100%;
    background: var(--footerbg);
    color: var(--text);
    overflow-y: auto;
  }
  .firstContainer h4{
    padding: 0px 0;
    font-size: 11px;
  }
  .secondContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 67% 30%;
    overflow-y: auto;
  }
  .content{
    padding: 0 10px 10px 10px;
  }
  .fileBox{
    margin-top:25px ;
  }
  .fileDownloader{
    text-decoration: none;
    color: white;
    background: var(--primary-color);
    padding: 7px;
    border-radius: 5px;
    margin-top: 15px;
  }

  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  .refRow h4{
    padding-right: 20px;
  }
  .menuCloseIcon{
    height: 30px;
    width: 30px;
    color: var(--primary-color);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: cursive;
  }

@media only screen and (max-width: 826px) {
    .imgHeight{
        width: 100%;
    }
    .iframeTag{
        width:100%;
    }
    .content{
        margin-right: 0px;
        padding: 10% 0 0;
    }
    .refRow{
        display: block;
        padding-bottom: 40px;
    }
    .codeContainer{
        height: 100px;
        width: 100%;
        padding-bottom: 60px;
    }
      .isMoile{
        display: none;
      }
      .mainContainer{
        padding: 8% 2% 2% 2%;
        display: grid;
        grid-template-columns: 100%;
        overflow-x: hidden;
        height: 87vh;
    }
    .secondContainer{
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        overflow-y: auto;
    }
    .markettingSection{
        padding: 15px;
    }
    .firstContainer{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: white;
        overflow-y: auto;
        z-index: 1;
      }
}