.routingContent{
    padding-top: 90px;
}.languageCard{
    height: 250px;
    width: 250px;
    background: var(--primary-color);
    color: white;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.languageContainer{
    text-align: center;
    display: flex;
    justify-content: space-around;
}
.cardBg{
    color: var(--text);
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    box-shadow: 12px 12px 12px var(--footerbg);
}
.language:first-letter {
    text-transform: uppercase;
}
.loading{
    margin: 0;
}