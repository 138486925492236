.ContactContainer{
    display: grid;
    grid-template-columns: repeat(2,50%);
    align-items: center;
    text-align: center;
}
.LContact input::placeholder{
    color: var(--subText);
    font-size: .8rem;
}
.LContact textarea::placeholder{
    color: var(--subText);
    font-size: .9rem;
}
.LForm{
    text-align: center;
    margin: 3rem 0;
}
.LForm h1{
    color: var(--text);
}
.LForm input{
    height: 2rem;
    width: 15rem;
    border: .1rem solid var(--text);
    border-radius: .2rem;
    color: var(--text);
}
.LForm textarea{
    height: 2rem;
    width: 15rem;
    border: .1rem solid var(--text);
    border-radius: .2rem;
    height: 6rem;
    color: var(--text);
}
.LForm button{
    height: 2rem;
    width: 10rem;
    border: .1rem solid var(--text);
    border-radius: .2rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}
.LForm button:hover{
    background-color:var(--footerbg);
}
.LContact .socialRow{
    margin-top: 2rem;
    justify-content: start;
}