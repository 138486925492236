.logoSignup {
    display: grid;
    grid-template-columns: 0% 90% 10%;
    height: 80px;
    background: white;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 3px 2px 2px var(--primary-color);
}
.logoSignupMobile {
    display: grid;
    grid-template-columns: 0% 90% 10%;
    height: 80px;
    background: white;
    position: relative;
    width: 100%;
    z-index: 1;
    box-shadow: 3px 2px 2px var(--footerbg);
}

.headerChild{
    width: 100%;
    display: flex;
    align-items: center;
}
.burgerIcon{
    font-size: 20px;
    color: white;
    cursor: pointer;
    border-radius: 30px;
}
.hideMenu{
    display: none;
}
.logo{
    display: flex;
    align-items: center;
    font-size: 25px;
    font-family: fantasy;
    user-select: none;
    cursor: pointer;
}
.logo::first-letter{
    font-size: 32px;
    color: var(--primary-color);
}
.logoPdingLeft{
    padding-left: 20px;
}
.hideIcon{
    display: none;
}
@media only screen and (max-width: 826px) {
    .hideMenu{
        display: block;
    } 
    
.logoSignup{
    display: grid;
    grid-template-columns: 12% 60% 28%;
}
.logoSignupMobile{
    display: grid;
    /* grid-template-columns: 0% 90% 10%; */
    grid-template-columns: 0% 70% 30%;
}
.headerChild{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
}
}