.googleDiv{
    font-size: 20px;
    font-weight: 900;
    color: white;
    cursor: pointer;
    height: 40px;
    width: 90px;
    background: var(--primary-color);
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row{
    display: flex;
    justify-content: center;
  }
.adminMain{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.421);
}
.closeIcon{
    border: 2px solid white;
    text-align: center;
    color: white;
    font-size: 22px;
    background: var(--primary-color);
    height: 35px;
    width: 38px;
    cursor: pointer;
}
.alignLeft{
    position: absolute;
    right: 16px;
    top: 0px;
}
.addPost input{
    height: 30px;
    width: 90%;
}
.addCategory{
    width: 250px !important;
    height: 30px;
}
.category{
    padding-left: 10px;
}
.addPostRow textarea{
    height: 70px;
    width: 90%;
}
.techList{
    text-transform: capitalize;
}
.techList div{
    cursor: pointer;
}
.addPostRowContent input,textarea{
    border: 2px solid var(--primary-color);
}
.postBtn{
    height: 40px;
    width: 100px;
    border: none;
    background:var(--primary-color);
    color: white;
}
.addPost h1{
    color: var(--primary-color);
}
.addPostRowContent{
    text-align: start;
}
.multiTxt{
    width: 98.6%;
    height: 100px;
}
.multiImg{
    height: 29px !important;
    padding-top: 8px;
}
.deleteBtn{
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    height: 46px;
    width: 100px;
    margin: 0 10px;
    border-radius: 10px;
}
.newContent{
    padding: 5px 5px;
    margin: 10px 0;
}
.multiContent{
    height: 385px;
    width: 100%;
    overflow-x: hidden;
    overflow: auto;
    display: grid;
}
.uploadImg{
    height: 35px;
    width: 100px;
    text-align: center;
    padding-top: 10px;
    border-radius: 10px;
    background: rgb(95, 121, 39);
    color: white;
}
#imgId{
    display: none;
}
.postRow{
    display: grid;
    grid-template-columns: auto auto;
}
.twoBtn{
    display: flex;
}
.mobile{
    display: none;
}
.logout{
    text-align: end;
}
.logout{
    height: 35px;
    width: 70px;
    color:  var(--primary-color);
    background: white;
    font-weight: 900;
    border: none;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    text-align: center;
}
.login{
    text-align: center;
    align-items: center;
}
.login input{
    height: 35px;
    width: 300px;
    border: 2px double  var(--primary-color);
    outline: none;
    color: var(--primary-color);
    margin: 10px 0;
    border-radius: 5px;
}
.go{
    height: 35px;
    width: 307px;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
}
.loginForm{
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centerForm{
    text-align: center;
}
.adminSpan{
    color: white;
    text-shadow: 2px 2px 12px black;
}
.adminSpace{
    margin-top: 8px;
}
.commonInput{
    height: 35px;
    width: 200px;
    outline: none;
    border: 2px solid var(--primary-color);
    font-size: 18px;
    color: var(--primary-color);
}
.sideMenuMobile{
    position: relative;
    width: 250px;
    height: 100vh;
    text-align: center;
    box-shadow: 2px 2px 20px var(--primary-color);
}
.sideMenu{
    background: white;
    position: absolute;
    width: 250px;
    height: 100vh;
    text-align: center;
    box-shadow: 2px 2px 20px var(--primary-color);
}
.hideAdminMenu{
    display: none;
}

.adminLogo{
    font-size: 25px;
    padding-top:15px ;
    font-weight: 900;
    user-select: none;
}
.adminLogo::first-letter{
    font-size: 32px;
    color: var(--primary-color);
}
.sideMenu .logo{
    margin: 15px 0 0 0;
}
.menuList{
    margin-top: 20px;
}
.menu{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.activeBg{
    background: var(--primary-color);
    height: 50px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.rowSplit{
    display: flex;
    overflow: hidden;
    width: 100%;
}
.sideMenuIcon{
    margin: 10px;
    text-align: center;
    width: 35px;
    background: var(--primary-color);
    font-size: 20px;
    color: white;
    cursor: pointer;
    height: 35px;
    border-radius: 30px;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoutMenu{
   position: absolute;
   bottom: 0;
   width: 100%;
   background: var(--primary-color);
   color: white;
   font-weight: 900;
}
.rightColumn{
    overflow-y: auto;
    height: 90vh;
    overflow-x: hidden;
    padding: 30px 20px;
}
.languageList{
    height: 40px;
    width: 112px;
    text-align: center;
    font-size: 16px;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

@media only screen and (max-width: 600px) {
    .rightColumn{
        padding: 30px 20px;
    }
    .addPostRow{
        text-align: center;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .multiContent{
        height: 200px;
        width: 100%;
        overflow-x: hidden;
        overflow: auto;
        display: grid;
        justify-content: center;
        grid-template-columns: auto;
    }
    .postRow{
        display: grid;
        grid-template-columns: auto;
    }
    .mobile{
        display: block;
        text-align: end;
        padding-bottom: 30px;
    }
    .desktop{
        display: none;
    }
    .dynamicDiv{
        text-align: center;
    }
    .alignLeft{
        position: absolute;
        right: 0px;
        top: 0px;
    }
}