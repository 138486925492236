.pageBg{
    background-color: #EDF9FE;
}
.LName{
    text-align: center;
    color: #001C55;
}
.LName h3{
    margin: 0;
    font-size: 1.5rem;
    font-family: cursive;
    cursor: pointer;
}
.LHeader{
    display: grid;
    grid-template-columns: 30% 70%;
    height: 5rem;
    align-items: center;
}
.LMenuList{
    display: flex;
    justify-content: center;
}
.LMobileMenuList{
    display: none;
}
.LMenuList span{
    margin: 0 2rem;
    color: #001C55;
    cursor: pointer;
    width: 6rem;
    text-align: center;
    padding: .5rem;
}
.LMenuList span:hover{
    background-color: var(--footerbg);
    border-radius: 2px;
}
.active{
    font-weight: 900;
}
.LCross{
    display: none;
}
.LDo{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}
.LDo .card{
    padding: 0 5rem;
}
.card h2{
    color: var(--text);
    font-size: 2.5rem;
    margin: 0;
}
.card p{
    color: var(--subText);
    font-weight: 600;
    font-size: 1rem;
}
.center{
    text-align: center;
}
.cardImg{
    text-align: center;
}
.cardImg img{
    height: 500px;
}
.LServices{
    margin: 4rem 0 0 0;
}
.LServices h2{
    color: var(--text);
    font-size: 2rem;
    text-shadow: 
                -1px -1px 0 var(--footerbg),  
                 1px -1px 0 var(--footerbg),
                -1px  1px 0 var(--footerbg),
                 1px  1px 0 var(--footerbg);
    margin: 2rem 0 3rem 0;
}
.LSCards{
    display: grid;
    grid-template-columns: repeat(3,19%);
    justify-content: center;
    align-items: center;
    gap: 10rem;
}
.LSCard{
    height: 15rem;
    width: 15rem;
    border: .1rem solid var(--footerbg);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.LSCard h5{
    font-size: 1.3rem;
    font-weight: 900;
    color: var(--text);
    text-shadow: 
                -1px -1px 0 white,  
                 1px -1px 0 white,
                -1px  1px 0 white,
                 1px  1px 0 white;
}
.sImg1{
    background-image: url('../Assets/Landing/Service/web.png');
}
.sImg2{
    background-image: url('../Assets/Landing/Service/seo.png');
}
.sImg3{
    background-image: url('../Assets/Landing/Service/app.png');
}
.LProduct{
    padding: 4rem;
}
.LProduct h2{
    color: var(--text);
    font-size: 2rem;
    text-shadow: 
                -1px -1px 0 var(--footerbg),  
                 1px -1px 0 var(--footerbg),
                -1px  1px 0 var(--footerbg),
                 1px  1px 0 var(--footerbg);
    margin: 2rem 0 3rem 0;
}
.LPCards{
    display: flex;
    justify-content: center;
}
.LPCard{
    height: 15rem;
    width: 15rem;
    border: .1rem solid var(--footerbg);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}
.ptr{
    cursor: pointer;
}
.LPCard h5{
    font-size: 1.5rem;
    margin: .5rem 0;
    color: var(--text);
}
.LPCard p{
    font-size: .8rem;
    margin: 0;
    color: var(--subText);
    font-weight: 600;
}
.LFooter{
    background: var(--footerbg);
    text-align: center;
    padding: 3rem 0 0 20px;
  }
  .LFooter p{
    color: var(--text);
  }

  /* mobile view */
  @media (min-width: 700px) and (max-width: 1011px) {
    .LSCards{
        grid-template-columns: repeat(2,30%);
        justify-content: center;
        gap: 3rem;
    }
    .LDo{
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
    }
    .LDo .card {
        order: 1;
    }
    .cardImg{
        order: 2;
        text-align: center;
    }
  }
  @media only screen and (max-width: 700px) {
    .LHeader{
        height: 5.5rem;
        display: grid;
        grid-template-columns: 85% 15%;
    }
    .LName{
        text-align: start;
        padding-left: 2rem;
    }
    .LName h3{
        font-size: 2rem;
    }
    .LMenuList{
        display: none;
    }
    .Lmenu{
        display: flex;
        justify-content: center;
        transition: max-height 0.5s ease, opacity 0.5s ease;
    }
    .LCross{
        display: block;
        font-size: 2.5rem;
        font-weight: 600;
        font-family: cursive;
        opacity: 0;
        animation: fadeOut 2s forwards;
    }
    .LMobileMenuList{
        opacity: 0;
        animation: fadeOut 1s forwards;
    }
    @keyframes fadeOut {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .LMobileMenuList{
        display: block;
        margin: 0 2rem 2rem 2rem;
    }
    .LMobileMenuList div{
        padding: .8rem;
    }
    .LMobileMenuList div:active{
        background-color: var(--footerbg);

    }
    .Lmenu div div{
        height: 4px;
        width: 20px;
        border-radius: 2px;
        margin: 3px;
        background-color: var(--text);
    }
      .LSCards{
            grid-template-columns: 1fr;
            justify-content: center;
            gap: 3rem;
        }
        .LSCard,.LPCard {
            margin: 0 auto;
        }
        .LProduct{
            padding: 4rem 0;
        }
        .LPCards{
            display: flex;
            justify-content: center;
        }
         .pageBg{
            overflow: hidden;
         }
         .space{
            margin-top: 3rem;
         }
        .LDo{
            display: grid;
            grid-template-columns: 100%;
            align-items: center;
        }
        .LDo .card {
            order: 1;
            padding: 0 2rem;
        }
        .card h2{
            font-size: 1.6rem;
        }
        .cardImg{
            order: 2;
            text-align: center;
        }
        .cardImg img{
            height: 300px;
        }
        .LFooter{
            padding: 2rem 1rem 0;
        }
}
