.App {
  text-align: center;
}
*{
  scrollbar-width: thin;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
:root {
  --primary-color: rgb(11, 80, 105);
  --text:#001C55;
  --subText:#7F8DAA;
  --footerbg: #95b5cf;
  --card:#90CAF9;
  --subCard:#92E3A9;
}
.pColor{
  color: var(--primary-color);
}

.commonBtn{
  height: 45px;
  width: 100px;
  color: white;
  background: var(--primary-color);
  font-weight: 900;
  border: none;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* dashboard start */

.second_logo{
  font-size: 32px;
  background: var(--primary-color);
  color: white;
  user-select: none;
}
.second_logo:hover{
  color: var(--primary-color);
  background: white;
}
.containerMain{
  padding-top: 3rem;
  text-align: center;
}
.search{
 height: 30px;
 width: 290px; 
 caret-color: var(--text);
 color: var(--text);
 font-size: 18px;
 font-weight: 300;
 border-radius: 20px 0 0 20px;
 text-align: center;
 border: 2px solid var(--footerbg);
}
.search::placeholder{
  color: var(--primary-color);
  font-size: 1rem;
}
.main{
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 30px;
}
.center_div{
  justify-content: center;
}
.cards{
  cursor: pointer;
  margin: 30px;
  height: 250px;
  width: 250px;
  border-radius: 20px;
  border: solid 2px var(--footerbg);
  text-align: center;
  position: relative;
  box-shadow: 12px 12px 12px var(--footerbg);
}
.card_title{
  text-align: center;
  font-size: 25px;
}
.card_title:hover{
  text-align: center;
  font-size: 25px;
  color: var(--text);
}
.cardTitleContainer{
  --max-lines: 2; 
  overflow: hidden;
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.text_center::first-letter{
  text-transform: uppercase;
}
.card_title::first-letter{
  text-transform: uppercase;
}
.first_text{
  margin: 0;
  width: 100%;
  height: 140px;
  border-radius: 18px 18px 0 0;
  background-color: var(--footerbg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_center{
  font-size: 38px;
  font-weight: 900;
  color:white;
  text-shadow: 
                -1px -1px 0 var(--text),  
                 1px -1px 0 var(--text),
                -1px  1px 0 var(--text),
                 1px  1px 0 var(--text);
}
.search_button{
  position: absolute;
  height: 36px;
  width: 70px;
  border-radius: 0 30px 30px 0;
  color: var(--text);
  font-weight: 700;
  font-size: .8rem;
  background: var(--footerbg);
  border:none;
}
.search_container{
  margin-right: 68px;
  display: flex;
  justify-content: center;
}
.scrollTop{
  position: absolute;
  right: 30px;
  bottom: 30px;
  position: fixed;
}
.scrollTopBtn{
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: none;
  background: white;
  border: 5px solid var(--primary-color);
  color: black;
  box-shadow: -5px 4px 20px white;
  cursor: pointer;
}
.gSignup{
  margin: 20px 20px 20px 0;
  display: flex;
  align-items: center;
}
.displayName{
  font-size: 20px;
  font-weight: 800;
  color: var(--primary-color);
}
.postDelete{
  position: absolute;
  left: 0;
  background: white;
  color: var(--primary-color);
  height: 35px;
  width: 35px;
  font-size: 22px;
  border-radius: 12px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: cursive;
}
.postEdit{
  position: absolute;
  right: 0;
  background: white;
  color: var(--primary-color);
  height: 35px;
  width: 35px;
  font-size: 22px;
  border-radius: 12px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialRow{
  padding: 0;
  display: flex;
  justify-content: center;
}
.socialLogo{
  height: 40px;
  width: 40px;
  padding-right: 30px;
}
.socialLogoYt{
  height: 40px;
  width: 40px;
}
.footer{
  background: var(--primary-color);
  text-align: center;
  color: white;
  padding: 20px 0 0 20px;
}
.copyRight{
  margin:0;
  padding: 20px 0;
}
.copyRightText{
  margin: 0;
  padding: 7px 0 0 0;
}
.footerAlignment{
  position: absolute;
  width: 100%;
  bottom: 0;
}
@media only screen and (max-width: 1300px) {
  .main{
      display: grid;
      grid-template-columns: auto auto auto auto;
      margin: 4rem 0 2rem 0;
  }
  .cards{
      margin: 30px;
      height: 250px;
      width: 250px;
      border-radius: 20px;
      text-align: center;
      position: relative !important;
      box-shadow: 8px 12px 12px var(--footerbg);
  }
}
@media only screen and (max-width: 1100px) {
  .main{
      display: grid;
      grid-template-columns: auto auto auto;
      margin: 30px;
  }
}
@media only screen and (max-width: 900px) {
  .main{
      display: grid;
      grid-template-columns: auto auto;
      margin: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .main{
      display: grid;
      grid-template-columns: auto;
      margin: 30px;
  }
}

/* dashboard end */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
